import TYPES from '@/types';

import format from 'date-fns/format';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchTransactionsByRangeQuery,
} from '@/modules/my-investment/allianz/transaction/application/queries';
import {
  CancelTransactionCommand,
} from '@/modules/my-investment/allianz/transaction/application/commands';
import SearchTransactionStatusQuery
  from '@/modules/my-investment/allianz/transaction-status/application/queries/search-transaction-status-query';

// Domain
import {
  TransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/transaction-entity';
import {
  PendingTransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/pending-transaction-entity';
import {
  CancelTransactionDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/cancel-transaction-dto';
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { Values } from '@/modules/shared/domain/i18n/types';

export type FormattedStatus = {
  tooltip: string;
  status: string;
}

export default class RequestsViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY)
  private readonly search_allianz_transactions_query!: SearchTransactionsByRangeQuery;

  @Inject(TYPES.UPDATE_ALLIANZ_TRANSACTIONS_COMMAND)
  private readonly cancel_allianz_transactions_command!: CancelTransactionCommand;

  @Inject(TYPES.SEARCH_TRANSACTION_STATUS_QUERY)
  private readonly search_transaction_status_query!: SearchTransactionStatusQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.allianz-dashboard.allianz_requests';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  is_loading = true;

  requests: Array<PendingTransactionEntity> = [];

  transaction_selected: PendingTransactionEntity = {
    id: '',
    will_be_processed: '',
    accreditation_date: '',
    allianz_account_id: '',
    policy_number: 0,
    amount: '',
    transaction_status: '',
    transaction_status_label: '',
    transaction_status_tooltip: '',
    transaction_type: '',
    transaction_type_label: '',
    reflected_date: '',
    estimated_collection_date: '',
    show_reflected_estimated_contribution_dates: false,
  };

  show_detail_dialog = false;

  show_confirmation_dialog = false;

  request_canceled_successfully = false;

  status_canceled_id = '';

  allianz_account: AllianzAccountEntity = new State().account;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  formatStatus = (status: string, reason: string, accreditation_days: number): FormattedStatus => {
    switch (status) {
      case 'Cancelled':
        return { status: 'Solicitud cancelada', tooltip: 'La solicitud ha sido cancelaca' };
      case 'Rejected':
        return { status: 'Rechazada', tooltip: `La solicitud ha sido rechazada debido a ${reason}` };
      case 'Sent':
        return {
          status: 'Solicitud enviada',
          tooltip: 'Hemos enviado tu solicitud a Allianz'
            + ' para su acreditación. Este proceso tardará "2 días hábiles"',
        };
      case 'Pending':
        return {
          status: 'Solicitud pendiente',
          tooltip: `Hemos recibido tu solicitud en "${accreditation_days} días hábiles" será enviada a `
            + ' Allianz para su procesamiento y acreditación"',
        };
      case 'Successful':
        return {
          status: 'Solicitud exitosa',
          tooltip: 'La solicitud fue aplicada de forma exitosa',
        };
      default:
        return {
          status: '',
          tooltip: '',
        };
    }
  }

  calculateAccreditationDays = (start_date: string, end_date: string): number => {
    const start_date_as_date = new Date(start_date);
    const end_date_as_date = new Date(end_date);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const diff_time = Math.abs(start_date_as_date - end_date_as_date);
    const diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));
    return diff_days;
  }

  formatRequests = (requests: Array<TransactionEntity>) => {
    const contribution_types = ['RecurringContribution', 'Deposit', 'PaymentRetry'];
    this.requests = requests.map((request) => {
      const accreditation_days = this.calculateAccreditationDays(
        request.will_be_processed,
        request.accreditation_date,
      );
      const transaction_status_and_tooltip = this.formatStatus(
        request.transaction_status.name,
        '',
        accreditation_days,
      );
      const show_reflected_estimated_contribution_dates = (contribution_types.indexOf(
        request.transaction_type.name,
      ) > -1);

      return {
        id: request.id,
        will_be_processed: this.formatDate(request.will_be_processed),
        accreditation_date: request.processed_by_allianz_on ? this.formatDate(
          request.processed_by_allianz_on,
        ) : this.translate('pending'),
        allianz_account_id: request.allianz_account_id,
        policy_number: request.policy_number,
        amount: currencyFormat(request.amount),
        transaction_status: request.transaction_status.name,
        transaction_status_label: transaction_status_and_tooltip.status,
        transaction_status_tooltip: transaction_status_and_tooltip.tooltip,
        transaction_type: request.transaction_type.name,
        transaction_type_label: request.transaction_type.description,
        reflected_date: show_reflected_estimated_contribution_dates && request.reflected_date
          ? this.formatDate(request.reflected_date) : '-',
        estimated_collection_date: show_reflected_estimated_contribution_dates
        && request.estimated_collection_date ? this.formatDate(request.estimated_collection_date)
          : '',
        show_reflected_estimated_contribution_dates,
      };
    });
  }

  loadTransactions = async () => {
    try {
      const initial_date = new Date();
      initial_date.setDate(initial_date.getDate() - 15);
      initial_date.setHours(0, 0, 0, 0);
      const initial_date_iso = format(initial_date.toISOString(), 'YYYY-MM-DDThh:mm:ss');
      const final_date = new Date();
      final_date.setHours(24, 0, 0, 0);
      const final_date_iso = format(final_date.toISOString(), 'YYYY-MM-DDThh:mm:ss');
      const params = {
        initial_date: initial_date_iso,
        final_date: final_date_iso,
      };
      const requests = await this.search_allianz_transactions_query
        .execute(params);
      this.formatRequests(requests);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_pending_transactions'));
    }
  }

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (this.allianz_account) {
        await this.loadTransactions();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  addCreatePendingTransactionEventListener = () => {
    window.addEventListener('allianz.create.pending.transaction', this.loadTransactions);
  }

  removeCreatePendingTransactionEventListener = () => {
    window.removeEventListener('allianz.create.pending.transaction', this.loadTransactions);
  };

  cancelRequest = () => {
    this.show_detail_dialog = false;
    this.show_confirmation_dialog = true;
  }

  confirmCancel = async () => {
    try {
      const cancel_transaction_dto: CancelTransactionDto = {
        transaction_id: this.transaction_selected!.id,
        transaction_status_id: this.status_canceled_id,
      };
      await this.cancel_allianz_transactions_command.execute(
        cancel_transaction_dto,
      );
      this.restoreRequestSelected();
      this.show_detail_dialog = false;
      this.show_confirmation_dialog = false;
      this.request_canceled_successfully = true;
      await this.loadTransactions();
      const transaction_event = new CustomEvent('allianz.cancel.pending.transaction', { detail: { ...cancel_transaction_dto } });
      window.dispatchEvent(transaction_event);
    } catch {
      this.message_notifier.showErrorNotification(
        'Ha ocurrido un error al cancelar la solicitud, inténtalo nuevamente',
      );
    }
  }

  setStatusCanceledId = async () => {
    try {
      const transaction_status = await this.search_transaction_status_query.execute();
      const status_cancelled = transaction_status.find((status) => status.name === 'Cancelled');
      if (status_cancelled) {
        this.status_canceled_id = status_cancelled.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(
        'Ha ocurrido un error al cancelar la solicitud, inténtalo nuevamente',
      );
    }
  }

  showDetail = (transaction: PendingTransactionEntity) => {
    this.transaction_selected = transaction;
    this.show_detail_dialog = true;
  }

  closeDetail = () => {
    this.show_detail_dialog = false;
  }

  formatDate(date: string) {
    return date ? this.date_formatter.formatDate(new Date(date), 'DD/MM/YYYY') : '';
  }

  restoreRequestSelected = () => {
    this.transaction_selected = {
      id: '',
      will_be_processed: '',
      accreditation_date: '',
      allianz_account_id: '',
      policy_number: 0,
      amount: '',
      transaction_status: '',
      transaction_status_label: '',
      transaction_status_tooltip: '',
      transaction_type: '',
      transaction_type_label: '',
      reflected_date: '',
      estimated_collection_date: '',
      show_reflected_estimated_contribution_dates: false,
    };
  }

  initialize = async () => {
    await this.setStatusCanceledId();
    await this.loadAllianzAccount();
    this.is_loading = false;
  }
}
