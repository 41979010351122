































































































































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import RequestsViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/requests-view-model';

@Component({
  name: 'RequestsDialog',
  filters: { currencyFormat },
  components: {
    RequestDetailDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/pending-requests/RequestDetailDialog.vue'),
    ConfirmCancelRequestDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/pending-requests/ConfirmCancelRequestDialog.vue'),
    RequestCanceledSuccessfullyDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/pending-requests/RequestCanceledSuccessfullyDialog.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RequestsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  requests_view_model = Vue.observable(new RequestsViewModel());

  close() {
    this.requests_view_model.closeDetail();
    this.synced_is_open = false;
  }

  async created() {
    this.requests_view_model.addCreatePendingTransactionEventListener();
    await this.requests_view_model.initialize();
  }
}
